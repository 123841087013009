<template>
  <div class="NewLayout">
   <myNewMenu></myNewMenu>
   <div id="layoutContant">
      <router-view></router-view>
   </div>
  </div>
</template>

<script>
import myNewMenu from "@/components/newcomponents/myNewMenu"
export default {
  name: 'NewLayout',
  components: {
   // HelloWorld
   myNewMenu,
  //  ToolView
  },
  data(){
    return {

    }
  },
  mounted(){},
  methods:{

  }
}
</script>
<style scoped>
.NewLayout{
  background: #E8E8ED;
}
</style>
