<template>
    <div id="contentFoot">
        <ul>
            <li v-for="(item,i) in contentFootList" :key="item.id" @click.stop="getShow(item.Id,i)">
                <div class="itemMun" v-show="item.show">
                    <div @click.stop="goLink(i)" class="itemText" :class="{color1:(item.Pid===0),'color2':(item.Pid !==0)}" >{{item.Name}}</div>
                    <div><i :class="[item.icon,(item.Pid===0?'color1':'color3')]" class="itemIcon"></i></div>
                </div>
            </li>
            <li>
                <div class="itemMun">
                    <div class="itemText color1" style="margin-right:25px;" @click="goIndex">返回首页</div>
                    <div class="color1" style="position: relative;" @click="ScrollTop"><span class="top">TOP</span></div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name:'conttentfoot',
    data(){
        return {
            contentFootList:[
                {
                    Name:"企业介绍",
                    Id:1,
                    Pid:0,
                    path:"/group",
                    component:'CorporateCulture',
                    icon:'el-icon-arrow-right',
                    show:true
                },
                 {
                    Name:"企业文化",
                    Id:2,
                    Pid:1,
                    path:"/group",
                    component:'CorporateCulture',
                    icon:'el-icon-arrow-down',
                    show:false
                },
                //  {
                //     Name:"最新动态",
                //     Id:3,
                //     Pid:1,
                //     path:'/group',
                //     component:'LatestNews',
                //     icon:'el-icon-arrow-down',
                //     show:false
                // },
                 {
                    Name:"业务板块",
                    Id:4,
                    Pid:0,
                    path:'/plate',
                    component:'BusinessPlate',
                    icon:'el-icon-arrow-right',
                     show:true
                },
                 {
                    Name:"业务板块",
                    Id:5,
                    Pid:4,
                    path:'/plate',
                    component:'BusinessPlate',
                    icon:'el-icon-arrow-down',
                    show:false
                },
                {
                    Name:"商业合作",
                    Id:6,
                    Pid:0,
                    path:'/business',
                    component:'brand',
                    icon:'el-icon-arrow-right',
                    show:true
                },
                {
                    Name:"品牌商入驻",
                    Id:7,
                    Pid:6,
                    path:'/business',
                    component:'brand',
                    icon:'el-icon-arrow-down',
                     show:false
                },
                {
                    Name:"供应商入驻",
                    Id:8,
                    Pid:6,
                    path:'/business',
                    component:'supplier',
                    icon:'el-icon-arrow-down',
                    show:false
                },
                {
                    Name:"分销商入驻",
                    Id:9,
                    Pid:6,
                    path:'/business',
                    component:'distribution',
                    icon:'el-icon-arrow-down',
                     show:false
                },
                {
                    Name:"商学院",
                    Id:10,
                    Pid:6,
                    path:'/business',
                    component:'student',
                    icon:'el-icon-arrow-down',
                     show:false
                },
                {
                    Name:"合伙人加盟",
                    Id:11,
                    Pid:6,
                    path:'/business',
                    component:'partner',
                    icon:'el-icon-arrow-down',
                    show:false
                },
                 {
                    Name:"进度查询",
                    Id:15,
                    Pid:6,
                    path:'/business',
                    component:'query',
                    icon:'el-icon-arrow-down',
                    show:false
                },
              
                {
                    Name:"联系我们",
                    Id:13,
                    Pid:12,
                    ath:'/joinus',
                    component:'contactus',
                    icon:'el-icon-arrow-down',
                     show:false
                },
                {
                    Name:"人才招聘",
                    Id:14,
                    Pid:12,
                    path:'/joinus',
                    component:'recruitment',
                    icon:'el-icon-arrow-down',
                     show:false
                }
            ]
        }
    },
    methods:{
        goIndex(){
            this.$router.push('/index')
        },
        goLink(index){
           //路由跳转
         this.$router.push({
              path:this.contentFootList[index].path,
              query:{
                name:this.contentFootList[index].component
              }
          })
        },
        ScrollTop(){
            // let Event = document.documentElement ||  document.body
            // Event.scrollTop =0
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        },
        getShow(id,index){
            this.contentFootList.forEach((item,i) =>{
                if(item.Pid==0 && item.Id != id){  //处理父级图标的默认方式的显示方式
                    item.icon='el-icon-arrow-right'
                }
                if(item.Pid>0){ //处理子级的显示方式
                     if(id == item.Pid){
                       item.show = !item.show
                     }else{
                        item.show = false
                     }
                }
            })
            //当前改变图标
            if(this.contentFootList[index].icon =='el-icon-arrow-right'){
               this.contentFootList[index].icon ='el-icon-arrow-down';
            }else{
               this.contentFootList[index].icon ='el-icon-arrow-right';
            }
            
          
        }
    }
}
</script>
<style scoped>
#contentFoot{
    padding: 40px 0 20px 0;
    background: linear-gradient(267deg, #F6944B, #FA6A2E);
    font-family: Source Han Sans CN;
    font-weight: 400;
}
#contentFoot ul li >div{
    display: flex;
    justify-content:space-between;
    flex-direction:row;
}
.itemMun{
    cursor: pointer;
    padding: 10px 0 10px 0;
}
.itemText:hover{
  color: #FFF !important;
}
.color1{
    color: #FFF;
}
.color2{
    color: #FFBE9F;
}
.color3{
    color: transparent;
}
.top{
    position: absolute;
    top: 0;
   left: -27px;
}

</style>


<style lang="scss" scoped>
/*手机端*/
@media  screen and (max-width:760px) {
    #contentFoot{
       font-size: 18px;
       display: block;
       padding: 30px 40px;
    }
}

/*PC端*/
@media  screen and(min-width:1200px) {
     #contentFoot{
       font-size: 30px;
       display: none;
       color: #000 !important;
    }
}
</style>