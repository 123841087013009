<template>
    <div class="NewMenuView" ref="NewMenuView">
        <div class="myNewMenu u-min-width">
            <div class="logoImgView"><img style="height:50px" src="../../assets/myimage/logo.png"></div>
            <div class="MenuView u-flex u-row-between">
                <ul class="u-flex u-m-l-60">
                    <li class="u-p-r-40">
                        <div @click="handleClick('/indexAdmin',1)"><span :class="{'active':active =='indexAdmin'}">首页</span></div>
                    </li>
                    <li class="u-p-r-40">
                        <div @click="handleClick('/generalAdmin',2)"><span :class="{'active':(active =='generalAdmin' || active =='corporationAdmin' ||active =='setWordAdmin' || active =='civilizationAdmin')}">关于我们</span></div>
                        <!-- <div class="MunList">
                            <div @click="handleClick('/corporationAdmin',2)">企业介绍</div> -->
                            <!-- <div @click="handleClick('/setWordAdmin',2)">发展历程</div> -->
                            <!-- <div @click="handleClick('/civilizationAdmin',2)">企业文化</div>
                        </div> -->
                    </li>
                    <li >
                        <div @click="handleClick('/ERPShopAdmin',11)"><span :class="{'active':(active =='ERPShopAdmin' || active=='ERPAdmin')}">业务板块</span></div>
                        <div class="MunList">
                            <div @click="handleClick('/ERPShopAdmin',11)">物联网连接管理平台</div>
                            <div @click="handleClick('/ERPAdmin',11)">芯动云App</div>
                            <div @click="handleClick('/ERPSIM',99)">物联网卡</div>
                        </div>
                    </li>
                    <li class="u-p-r-40">
                            <div @click="handleClick('/commercialAdmin',12)"><span :class="{'active':(active =='commercialAdmin' || active=='mediaAdmin' || active=='SupplierAdminform')}">通信终端</span></div>
                            <div class="MunList">
                                <div @click="handleClick('/commercialAdmin',12)">UFI</div>
                                <div @click="handleClick('/mediaAdmin',12)">MIFI</div>
                                 <div @click="handleClick('/SupplierAdminform',12)">CPE</div>
                            </div>
                            
                    </li>
                    <li class="u-p-r-40">
                        <div @click="handleClick('/contactAdmin',15)"><span :class="{'active':(active =='contactAdmin' || active=='contactAdmin')}">联系我们</span></div>
                        <div class="MunList">
                            <!-- <div @click="handleClick('/JoinusAdmin',15)">人才招聘</div> -->
                            <div @click="handleClick('/contactAdmin',15)">联系我们</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="floatView">
                <div class="floatView-foot">
                    <!--                
                    <div>
                        <div class="floatViewIcon" style="background-position: -19px -130px;"></div>
                        <div class="u-m-t-4">微信公众号</div>
                    </div>
                    <div class="floatView-link"></div>
                    <div @click="getURL('/new/supplierAdmin')">
                        <div class="floatViewIcon" style="background-position: -19px -190px;"></div>
                        <div class="u-m-t-4">供应商入驻</div>
                    </div> -->
                    <!-- <div class="floatView-link"></div> -->
                    <div @click="getTop">
                        <div class="top"><i class="el-icon-upload2"></i></div>
                        <div class="u-m-t-4">TOP</div>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="MobilemyNewMenuView">
            <div class="MobilemyNewMenu">
                <div class="logoImgView"><img style="height:30px" src="../../assets/myimage/logo.png"></div>
                <div class="MenIcon" @click="showright">
                    <svg t="1680171372056" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6778" width="25" height="25"><path d="M981.3 213.3H42.7C19.1 213.3 0 194.2 0 170.6s19.1-42.7 42.7-42.7h938.7c23.6 0 42.7 19.1 42.7 42.7s-19.2 42.7-42.8 42.7zM981.3 554.7H42.7C19.1 554.7 0 535.6 0 512s19.1-42.7 42.7-42.7h938.7c23.6 0 42.7 19.1 42.7 42.7-0.1 23.5-19.2 42.7-42.8 42.7zM981.3 896H42.7C19.1 896 0 876.9 0 853.3s19.1-42.7 42.7-42.7h938.7c23.6 0 42.7 19.1 42.7 42.7-0.1 23.6-19.2 42.7-42.8 42.7z" fill="#FFFFFF" p-id="6779"></path></svg>
                </div>
            </div>
        </div>
        <div class="myNewMenuItemView" ref="myNewMenuItemView">
                <ul>
                    <li>
                        <div @click="handleClick('/indexAdmin',99)"><span>首页</span></div>
                    </li>
                    <li>
                        <div @click="handleClick('/generalAdmin',99)"><span>企业概况</span></div>
                        <div class="MunList">
                            <div @click="handleClick('/corporationAdmin',99)">企业介绍</div>
                            <!-- <div @click="handleClick('/setWordAdmin',99)">发展历程</div> -->
                            <div @click="handleClick('/civilizationAdmin',99)">企业文化</div>
                        </div>
                    </li>
                    <li >
                        <div @click="handleClick('/ERPShopAdmin',99)"><span>业务板块</span></div>
                        <div class="MunList">
                            <div @click="handleClick('/ERPShopAdmin',99)">ONEBO分销平台</div>
                            <div @click="handleClick('/ERPAdmin',99)">ONEBO ERP</div>
                            <div @click="handleClick('/ERPAdmin',99)">ONEBO ERP</div>
                        </div>
                    </li>
                    <li>
                            <div @click="handleClick('/commercialAdmin',99)"><span>商业合作</span></div>
                            <div class="MunList">
                                <div @click="handleClick('/commercialAdmin',99)">商务合作</div>
                                <div @click="handleClick('/mediaAdmin',99)">媒体合作</div>
                                <div @click="handleClick('/SupplierAdminform',99)">供应商入驻</div>
                            </div>
                            
                    </li>
                    <li>
                        <div @click="handleClick('/supplierAdmin',99)"><span>供应链招商</span></div>
                    </li>
                    <li>
                        <div  @click="handleClick('/newsAdmin',99)"><span>新闻资讯</span></div>
                    </li>
                    <li>
                        <div @click="handleClick('/JoinusAdmin',99)"><span>加入我们</span></div>
                        <div class="MunList">
                            <div @click="handleClick('/JoinusAdmin',99)">人才招聘</div>
                            <div @click="handleClick('/contactAdmin',99)">联系我们</div>
                        </div>
                    </li>
                </ul>
        </div>
    </div>
</template>
<script>
export default {
    name:'myNewMenu',
    data(){
        return {
           active:1,
           listion:false
        }
    },
     created() {
           this.active = this.$route.path.replace(/\//, '').split("/")[0];
           console.log(this.$route.path)
     },
     watch:{
        "$route.path":function(){
           this.active = this.$route.path.replace(/\//, '').split("/")[0];
        }
     },
    mounted(){
         window.addEventListener('scroll',this.handleScroll);
        //  window.addEventListener("click",()=>{
          
        //     document.querySelector(".myNewMenuItemView").style.right= "-200px"
        //  })
    },
    methods:{
        handleScroll(){
            let scrollTop = window.pageXOffset || document.documentElement.scrollTop ||  document.body.scrollTop
            if(scrollTop>60){
                this.$refs.NewMenuView.style.backgroundColor= "#333333";
                this.$refs.NewMenuView.style.borderBottom= "none"
            }else{
                this.$refs.NewMenuView.style.backgroundColor= "#333";
                this.$refs.NewMenuView.style.borderBottom= "2px solid #ffffff91"
            }
        
        
        },
        handleClick(str,type) {
            this.active =type;
            this.$router.push({
                path: str
            })
            if(type ==99){
                 this.listion =false;
                 this.$refs.myNewMenuItemView.style.right= "-200px";
            }
        },
        
        getTop(){
             window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        },
        getURL(url){
            this.$router.push({
                path:url
            })
        },
        showright(){
              if(!this.listion){
                 this.$refs.myNewMenuItemView.style.right= "0px";
              }else{
                 this.$refs.myNewMenuItemView.style.right= "-200px";
              }
              this.listion =!this.listion
        }
    }
}
</script>
<style lang="scss" scoped>
    @keyframes example {
        from {
              height: 0;
              margin-top: 0;
            }
        to {
              height: auto;
              margin-top: 10px;
            }
    }
   a{
     text-decoration: none;
      color: #333333;
   }
   .active{
       border-bottom: 2px solid;
       border-image-source: linear-gradient(to right, #FFF, #FA863A);
       border-image-slice: 1;
   }
    .NewMenuView{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background: #333333;
        border-bottom: 2px solid #ffffff91;
        &:hover{
            background: #333333 !important;
        }
        .myNewMenu{
            display: flex;
            justify-content: space-between;
            align-items:flex-start;
            padding-top: 30px;
            padding-bottom: 20px;
        .MenuView{
            height: 100%;
            ul{
                 height: 100%;
                 justify-content:flex-start;
                 align-items:flex-start;
                li{
                    height: 100%;
                    display: flex;
                    align-items:flex-start;
                    flex-direction: column;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFF;
                    cursor: pointer;
                     & span{
                            padding-bottom: 4px;
                         }
                    .MunList{
                        margin-top: 0;
                        height: 0;
                        overflow: hidden;
                        & div{
                            margin-top: -2px;
                            height: 30px;
                            line-height: 30px;
                            font-size: 13px;
                            text-align: left;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFF;
                            cursor: pointer;
                            &:hover{
                                color:#FA863A;
                            }
                        }
                    }
                    &:hover{
                         & span{
                            border-bottom: 2px solid;
                            border-image-source: linear-gradient(to right, #FFF, #FA863A);
                            border-image-slice: 1;
                         }
                        .MunList{
                            margin-top: 10px;
                            height: auto;
                            transition: 1s ease-in-out ;
                              & div{
                                 margin-top: 0px;
                                 transition: margin-top 1s ease-in-out ;
                              }
                        }
                    }
                }
            }
            .MenuRight{
                height: 100%;
                & div{
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 20px;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    cursor: pointer;
                }
            }
        }
       
        .activeright{
            background: #FD681C;
            color:#FFF !important
        }
    }
    .floatView{
        position: fixed;
        z-index: 100;
        top: 40%;
        right: 7%;
        .floatView-top{
            width: 70px;
            height: 66px;
            background: #FD681C;
            box-shadow: 0px 3px 14px 1px rgba(0,0,0,0.11);
            border-radius: 4px 4px 4px 4px;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
        }
        .floatView-foot{
            width: 70px;
            // height: 300px;
            margin-top: 14px;
            padding: 10px 0;
            background: #FFFFFF;
            box-shadow: 0px 3px 14px 1px rgba(0,0,0,0.11);
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            & div{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                .floatViewIcon{
                    width: 20px;
                    height: 20px;
                    background: url("../../assets/images/newImg/footMune.png");
                }
                .top{
                    font-size: 26px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: bold;
                    color: #000 !important;
                }
            }
            .floatView-link{
                width: 25px;
                height: 1px;
                background:  #EBEBEB;
            }
        }
    }
    }

    .MobilemyNewMenuView{
        .MobilemyNewMenu{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            padding: 0 15px;
        }
    }
    .myNewMenuItemView{
         width: 200px;
         position: fixed;
         height: cale();
         top: 50px;
         right: -200px;
         background:#333333 ;
         padding:8px 0;
         height: 100%;
         transition: right 1s ease;
         & ul{
            width: 100%;
            & li{
                color: #FFF;
                font-size: 18px;
                padding-left: 20px;
               & div:nth-child(1){
                  height: 32px;
                  line-height: 32px;
                   &:hover{
                     color: #FA863A;
                }
               }
               .MunList{
                   padding-left: 20px;
                   & div{
                       height: 32px;
                       line-height: 32px;
                        &:hover{
                            color: #FA863A;
                        }
                   }
               }
            }
         }
    } 
    
</style>
<style lang="scss" scoped>
/*PC端*/
@media  screen and(min-width:1200px) {
  
   .MobilemyNewMenuView{
      display: none !important;
    }
    .myNewMenuItemView{
          display: none !important;
    }
}
/*手机端*/
@media  screen and (max-width:760px) {
    .myNewMenu{
        display: none !important;
         
    }
    .MobilemyNewMenuView{
         display: block !important;
    }
    .myNewMenuItemView{
          display: block !important;
    }
    .floatView{
        display: none;
    }
}
</style>