<template>
  <div>
    <div class="topMun" ref="topMun">
      <div class="topMunText" style="display:none" title="一播·科技"><img src="@/assets/logo1.png"/></div>
      <div class="topMunText2" style="display:none" title="一播·科技"><img src="@/assets/logo3.png"/></div>
      <div id="LinkitemOne">
           <ul>
               <li v-for="(item,i) in LinkList" :key="i"  @click.stop="goLink(i)" @mouseover="onmouseover(i)" @mouseleave="onmouseleave(i)">
                    <div class="MunName" :class="{show:item.show}"><span :class="{action:(i===SelectNumber)}">{{item.name}}</span></div>
                    <transition name="topHeigth">
                      <div class="MunView" v-show="item.show">
                          <div v-for="(item2,index) in item.children" :key="index" @click.stop="goLinkTwo(i,item2.path,item2.component,index)">{{item2.name}}</div>
                      </div>
                    </transition>
                </li>
           </ul>
      </div>
      <div id="iconBut">
          <div class="topMunIcon" @click.stop="getShow">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <!-- <div class="triangle"  v-show="isShow"></div>
          <div class="MobileView" v-show="isShow">
              <div v-for="(item,i) in LinkList" :key="i" @click.stop="goLink(i)">
                  <span>{{item.name}}</span>
                </div>
          </div> -->
         
      </div>
    </div>
      <div class="MunView2" v-show ="isShow" > <!-- v-show ="isShow"   @click.stop="pp" -->
          <div class="MunViewContant">
              <div class="MunViewContantImg">
                  <img src="@/assets/logo4.png">
              </div>
              <div class="MunViewContantItem">
                    <ul>
                        <li v-for="(item,i) in LinkList" :key="i">
                            <div class="ParentMun" v-if="i!=0"  @click.stop="goshow(i,$event)"><span>{{item.name}}</span><i class="el-icon-arrow-right"></i></div>
                            <div class="childrenMun" v-if="i!=0" v-show="item.show">
                                <div v-for="(item2,index) in item.children" :key="index" @click.stop="goLinkTwo(i,item2.path,item2.component,index)">{{item2.name}}</div>
                            </div>
                        </li>
                    </ul>
              </div>
              <div class="MunViewContantIndex" @click.stop="goLinkTwo(0,'/index','index',0)">
                  返回首页
              </div>
          </div>
     </div>
  </div>
</template>

<script>
import { throttling } from "@/unit/public.js"
export default {
  name: 'topMun',
  data(){
    return{
         isthrottling:false, //防止事件重复调用
         isShow:false,
         SelectNumber:0,
         LinkList:[
            {
                name:'首页',
                path:'/index',
                show:false,
                children:[]
            },
            {
                name:'企业介绍',
                path:'/group',
                show:false,
                component:'CorporateCulture',
                children:[
                   {
                      name:'企业文化',
                      path:'/group',
                      component:'CorporateCulture'
                  },
                  // {
                  //     name:'最新动态',
                  //     path:'/group',
                  //     component:'LatestNews'
                  // }
                ]
            },
            {
                name:'业务板块',
                path:'/plate',
                show:false,
                component:'BusinessPlate',
                children:[
                   {
                      name:'业务板块',
                      path:'/plate',
                      component:'BusinessPlate'
                  },
                ]
            },
            {
                name:'商业合作',
                path:'/business',
                component:'brand',
                show:false,
                component:'brand',
                children:[
                  {
                     name:'品牌商入驻',
                     path:'/business',
                     component:'brand'
                  },
                  {
                    name:'供应商入驻',
                    path:'/business',
                    component:'supplier'
                  },
                  {
                      name:'分销商入驻',
                      path:'/business',
                      component:'distribution'
                  },
                  {
                      name:'商学院',
                      path:'/business',
                      component:'student'
                  },
                  {
                      name:'合伙人加盟',
                      path:'/business',
                      component:'partner'
                  },
                   {
                      name:'进度查询',
                      path:'/business',
                      component:'query'
                  }
                ]
            },
             {
                name:'联系我们',
                path:'/joinus',
                show:false,
                component:'contactus',
                // children:[
                //    {
                //       name:'联系我们',
                //       path:'/joinus',
                //       component:'contactus'
                //   },
                //   {
                //       name:'人才招聘',
                //       path:'/joinus',
                //       component:'recruitment'
                //   }
                // ]
            }
           ],
    }
  },
    watch:{
        $route:function(){
             this.LinkList.forEach((item,i) =>{
                if(this.$route.path ==item.path){
                  this.SelectNumber=i;
                }
             })
        }
    },
  mounted(){
    this.LinkList.forEach((item,i) =>{
      if(this.$route.path ==item.path){
        this.SelectNumber=i;
      }
    })
    window.addEventListener('scroll',this.handleScroll);
    window.addEventListener("click",()=>{
       if(this.isShow){
         this.isShow=false;
       }
       document.querySelector(".MunViewContant").style.marginRight= "-50%"
    })
  },
  methods:{
    handleScroll(){
        let scrollTop = window.pageXOffset || document.documentElement.scrollTop ||  document.body.scrollTop
        if(scrollTop>60){
            this.$refs.topMun.style.backgroundColor= "#333333"
        }else{
            this.$refs.topMun.style.backgroundColor= "transparent"
        }
       
      
    },
    goLink(index){
      this.SelectNumber = index;
      this.isShow = false;
      this.$router.push({
        path:this.LinkList[index].path,
        query: {
          name: this.LinkList[index].component
        }
      })
    },
    goLinkTwo(index,path,name){
      this.SelectNumber=index;
      sessionStorage.setItem("component",name)
      this.$router.push({
        path:path,
        query: {
          name: name
        }
      })
      this.isShow=false;
      document.querySelector(".MunViewContant").style.marginRight= "-50%"
    },
    onmouseover(index){
  
        let ClassName=document.getElementsByClassName("MunView")[index];
       
        this.LinkList.forEach((item,i) =>{
            item.show=false;
        })
        if(this.LinkList[index].children.length==0 ){
          this.LinkList[index].show=false;
        }else{
          this.LinkList[index].show=true;
        }
   
    },
    onmouseleave(index){
       this.LinkList[index].show=false;
    },
    getShow(){
        this.isShow= !this.isShow;
        setTimeout(()=>{
          document.querySelector(".MunViewContant").style.marginRight= "0"
        },100)
    },
    goshow(index,event){
       this.LinkList[index].show= !this.LinkList[index].show;
       let ClassName =document.getElementsByClassName("MunViewContantItem")[0].getElementsByTagName("i");
        try {
           if(ClassName[index-1].className=='el-icon-arrow-right')
              ClassName[index-1].className="el-icon-arrow-down";
           else
              ClassName[index-1].className="el-icon-arrow-right";
        } catch(e){

        }
      
      
    },
  
  }
}
</script>
<style scoped>
.MunView2{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  z-index: 120;
  background:rgba(0,0, 0, 0.4);
}
.MunViewContant{
  float:right;
  width:50%;
  height: 100%;
  background: #333333;
  margin-right: -50%;
  display: flex;
  flex-direction: column;
  transition: margin-right 0.7s ease-in-out 0s;
}
.MunViewContantImg{
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MunViewContantImg img{
  width: 100px;
}
.MunViewContantItem{
  flex: 1;
  overflow: auto;
}
.ParentMun{
  width: 100px;
  height: 40px;
  /* margin: 0 auto; */
  margin-left: 50%;
  transform: translateX(-42%);
  font-size:18px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.childrenMun{
  width: 100px;
  margin-left: 50%;
  transform: translateX(-42%);
  font-size:16px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #cdc5c5;
 text-align: left;
}
.childrenMun>div{
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.MunViewContantIndex{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size:18px;
  margin-top: 10px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #FFFFFF;
}
.topMun{
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  width: 100%;
  height: 90px;
  align-items: center;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
}
.topMunText img{
  width: 146px;
  height: 44px;
  margin-right: 60px;
  cursor: pointer;
}
.topMunText2  img{
  width:38px;
  margin-left: 20px;
  cursor: pointer;
}
#LinkitemOne{
  margin-left: 100px;
}
 #LinkitemOne>ul{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    overflow-y: hidden;
 }
  #LinkitemOne>ul>li{
    padding: 0 50px 0 50px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 19px;
    white-space: nowrap;
    height: 56px;
    line-height: 56px;
    text-align: center;
    cursor: pointer;
  }
  .MunName{
    width: 130px;
    height: 45px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .MunView{
    position: absolute;
    z-index: 100;
    background:#333333 ;
    font-size: 17px;
    text-align: center;
    width: 130px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .MunView>div{
    color: #b7babd;
  }
  .MunView>div:hover{
    color: #FFF;
  }
.action{
    border-bottom:3px solid transparent ;
    border-image: linear-gradient(to right,#F6944B,#FA6A2E) 1 10;
 }
.show{
    background:#333333 ;
}
/* 
.topHeigth-enter-active,
.topHeigth-leave-active {
  transition: all 0.5s ease;
}

.topHeigth-enter-from,
.topHeigth-leave-to {
  display: 0;
} */

.topMunIcon{
  width: 25px;
  height: 25px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.topMunIcon >span{
  display: block;
  width: 100%;
  height: 2px;
  background: #FFF;
}
.carouselView {
    width: 100%;
    height: 100%;
    display: flex;
    font-family: Source Han Sans CN;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.carouselView1{
    background-image: url('../assets/carousel-1.png');
    background-repeat: no-repeat;
    background-size:100% 100% ;
    color: #ffffff;
    /* background: #F6944B;
    border-bottom-right-radius: 25%;
    border-bottom-left-radius:25%; */
}
.carouselView2{
    color: #ffffff;
    background: #8d8681;
   
}
.carouselView3{
    color: #ffffff;
    background: #356375;
    
}
.ViewText{
   font-size: 40px;
   padding:10px 0 10px 0;
}
.ViewText2{
  font-size: 20px;
   padding:8px 0 8px 0;
}
.ViewButtons{
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ViewButton{
  padding: 8px 20px 8px 20px;
  border-radius: 30px;
  background: linear-gradient(93deg, #F98B3C, #FB6823);
}
.ViewButtons i{
   padding: 0 25px 0 25px;
   font-weight: bold;
}
#iconBut{
  position: relative;
}
.MobileView{
  position: absolute;
  top: 10px;
  right: 60px;
  background: #333333;
  border-radius: 15px;
  padding: 6px 0;
}
.triangle{
  border: 15px solid transparent;
  border-bottom-color: #333333;
  width: 1px;
  height: 1px;
  transform: rotate(-45deg);
  position: absolute;
  top: 15px;
  right: 50px;
}
.MobileView> div{
  width: 110px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
.MobileView> div >span:hover{
    border-bottom:5px solid transparent ;
    border-image: linear-gradient(to right,#F6944B,#FA6A2E) 1 10;
}
 
</style>
<style lang="scss" scoped>

/*手机端*/
@media  screen and (max-width:760px) {
  .topMun{
      height: 60px;
    }
    .topMunText2{
       display: block !important;
    }
    #LinkitemOne{
      display: none;
    }
    .topMun{
       justify-content: space-between;
    }
}

/*PC端*/
@media  screen and(min-width:1200px) {
  
   .topMunText{
      display: block !important;
    }
    #iconBut{
      display: none;
    }
}
</style>
