<template>
  <div class="layout">
   <top-mun></top-mun>
   <!-- <tool-view></tool-view> -->
   <div id="layoutContant">
      <router-view></router-view>
   </div>
  </div>
</template>

<script>
import TopMun from '@/components/TopMun.vue'
// import ToolView from '@/components/tool.vue'
export default {
  name: 'layout',
  components: {
   // HelloWorld
   TopMun,
  //  ToolView
  },
  data(){
    return {

    }
  },
  mounted(){},
  methods:{

  }
}
</script>
<style scoped>
.layout{
  background: #E8E8ED;
}
</style>
