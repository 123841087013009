<template>
   <!-- 圆圈 -->
    <div class="circular">
        <div class="circularView">
            <div class='circularText'>
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'circular',
    data(){
        return{

        }
    },
}
</script>
<style scoped>
  .circularView{
    width: 400px;
    height: 400px;
    border-radius: 100%;
    background:linear-gradient(to right,#FA6A2E,#F6944B);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circularText{
      width: 300px;
      height: 300px;
      border-radius: 100%;
      background: #E8E8ED;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}
.circularText span:nth-child(1){
    font-family: Source Han Sans CN;
    font-weight: 700;
    font-size: 28px;
    color: #333333;
    margin-top: -125px;
}
.circularText span:nth-child(2){
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #999999;
    font-size: 14px;
}
</style>
<style lang="scss" scoped>
@media  screen and (max-width:760px) {
 .circularView{
    width: 300px;
    height: 300px;
   
  }
  .circularText{
      width: 230px;
      height: 230px;
     
}
}
</style>