<template>
    <div class="index">
        <!-- 轮播图效果 -->
        <!-- <div class="container" :style="{height:ViewHeight}">
            <div class="banner" style="height:100%"> -->
                <div class="bannerView" :style="{height:ViewHeight}">
                     <div class="swiper-container" style="height:100%">
                        <div class="swiper-wrapper" style="height:100%">
                            
                            <div class="swiper-slide" style="height:100%;">
                                <div class="carouselView carouselView1">
                                    <div class="ViewText">海外一站式S2B2C服务平台</div>
                                    <div class="ViewText2">ONEBO是一个双向赋能供应链和分销商各大跨境电商一件分销S2B2C平台</div>
                                    <!-- <div class="ViewText2">货物进口贸易、一件分销跨境电商2B2C各太平台、海外短视频直播代运营/培训、陪跑、</div>
                                    <div class="ViewText2">ONEBO云仓服务、ONEBO海外仓服务、一站式孵化企业品牌出海</div> -->
                                    <div class="ViewButtons">
                                        <i class="el-icon-arrow-left"></i>
                                        <div class="ViewButton" @click="gobutton">了解ONEBO</div>
                                        <i class="el-icon-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide" style="height:100%;">
                                <div class="carouselView carouselView2" >
                                        <div class="ViewText">"微投轻创"跨境分销平台</div>
                                        <div class="ViewText2">"Micro investment and light innovation" cross-border distribution platform</div>
                                </div>
                            </div>
                            <div class="swiper-slide" style="height:100%;">
                                <div class="carouselView carouselView3">
                                        <div class="ViewText">让中国优质的产品走向世界</div>
                                        <div class="ViewText2">Let China's high-quality products go to the world</div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                    <div class="bannerImg">
                        <img src="../../assets/bannerImg.png">
                    </div>
                </div>
            <!-- </div>
        </div> -->
      
        <div class="indexView">
            <div class="indexCircular">
             <circular></circular>
            </div>
             <div class="indexContext">
                   <div class="indexLogo"><img width="193" height="60" src="../../assets/logo2.png"/></div>
                   <div class="indexConTitle">
                        广东一播科技有限公司，创建ONEBO平台，是一个双向赋能供应链和分销商各大跨境电商一件分销S2B2C平台，为企业一站式孵化品牌出海，帮助创业者” 低成本”实现跨境分销的公司。
                        <!-- 一播科技有限公司，是一家以货物进出口贸易、一件分销跨境电商2B2C各大平台、海外短视频直播代运营/培训/陪跑、
                        ONEBO云仓服务、ONEBO海外仓服务、一站式孵化企业品牌出海为六大主营板块的公司。 -->
                    </div>
                    <div class="link"></div>
                    <div class="Mapview">
                         <div>
                            <img src="../../assets/map.gif" />
                         </div>
                         <!-- <div>集团地址：广州市白云区鹤龙街道尖彭371号有趣创意社区A105号</div> -->
                    </div>
                    <div class="indexText">赋能供应链，扶持分销商</div>
                    <div class="oneboImg">
                        <img src="../../assets/onebo.png" >
                    </div>
                    <div class="link2"></div>
                    <div class="CompanyIntroduction">
                          <square>
                              <span>公司介绍</span>
                              <span>Company introduction</span>
                          </square>
                          <div class="CompanySquare">
                              <div class="square" @click.stop.prevent="getbvious(0,'/group','CorporateCulture')"><!--@click="goLisk('/group','CorporateCulture')"-->
                                    <div class="bg bg1">
                                        <div class="squareText">
                                            <div>公司简介 </div>
                                            <div>Company profile</div>
                                        </div>
                                        <div class="squarebutton" @click.stop="goLisk('/group','CorporateCulture')">点击查看详情</div>
                                         <div class="showtext">
                                            <div>
                                                广东一播科技有限公司，创建ONEBO平台，是一个双向赋能供应链和分销商各大跨境电商一件分销S2B2C平台，为企业一站式孵化品牌出海，帮助创业者”
                                                 低成本”实现跨境分销的公司。
                                                <!-- 通过ERP系统，为分销商(B)提供ONEBO商品一键同步到海外第三方平台 如：Tik Tok、shopify、shopee、lazada、
                                                速卖通等服务 <br/><br/>第三方平台消费者(C)成功下单，再计算成本， 为分销商(B)提供高质量营销策略服务、
                                                优质的产品、优惠的价格， 对多店铺进行智能系统管理， 创造微投轻创的低门槛跨境电商品牌出海项目。
                                                <br/><br/><br/>提供海外短视频直播代运营/海外短视频培训/免费运营一本通等服务 -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div class="square" @click.stop.prevent="getbvious(1,'/plate','BusinessPlate')"> <!--@click="goLisk('/plate','BusinessPlate')"-->
                                    <div class="bg bg2">
                                        <div class="squareText">
                                            <div>业务板块 </div>
                                            <div>Business segment</div>
                                        </div>
                                        <div class="squarebutton" @click.stop="goLisk('/plate','BusinessPlate')">点击查看详情</div>
                                        <div class="showtext">
                                            <div>
                                                 ONEBO供应链/分销商招商<br/>
                                                 ONEBO商学院<br/>
                                                 ONEBO云仓一件分销<br/>
                                                 ONEBO海外仓一件分销
                                                <!-- 通过ERP系统，为分销商(B)提供ONEBO商品一键同步到海外第三方平台 如：Tik Tok、shopify、shopee、lazada、
                                                速卖通等服务 <br/><br/>第三方平台消费者(C)成功下单，再计算成本， 为分销商(B)提供高质量营销策略服务、
                                                优质的产品、优惠的价格， 对多店铺进行智能系统管理， 创造微投轻创的低门槛跨境电商品牌出海项目。
                                                 <br/><br/><br/>提供海外短视频直播代运营/海外短视频培训/免费运营一本通等服务 -->
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                 <div class="square"  @click.stop.prevent="getbvious(2,'/business','brand')" ><!--@click="goLisk('/business','brand')"-->
                                    <div class="bg bg3">
                                        <div class="squareText">
                                            <div>商业合作 </div>
                                            <div>Business cooperation</div>
                                        </div>
                                        <div class="squarebutton" @click.stop="goLisk('/business','brand')">点击查看详情</div>
                                         <div class="showtext">
                                            <div>
                                                 品牌商入驻<br/>
                                                 供应商入驻<br/>
                                                 分销商入驻
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div class="square" @click.stop.prevent="getbvious(3,'/joinus','contactus')"> <!-- @click="goLisk('/joinus','contactus')"-->
                                    <div class="bg bg4">
                                        <div class="squareText">
                                            <div>加入我们</div>
                                            <div>Join us</div>
                                        </div>
                                        <div class="squarebutton" @click.stop="goLisk('/joinus','contactus')">点击查看详情</div>
                                         <div class="showtext">
                                            <div>
                                                 联系我们<br/>
                                                 人才招聘
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          </div>
                    </div>
                    <div class="OverviewView">
                         <square>
                              <span>公司概况</span>
                              <span>Overview company</span>
                          </square>
                          <div class="Overview">
                               <div class="OverviewItem OverviewItem1" @click="goLisk('/business','brand')">
                                    <div class="OverviewText">
                                            <div>品牌商入驻 </div>
                                            <div>brand</div>
                                            <div></div>
                                        </div>
                                        <div class="Overviewbutton">点击查看>></div>
                               </div>
                               <div class="OverviewItem OverviewItem2" @click="goLisk('/business','supplier')">
                                    <div class="OverviewText">
                                            <div>供销商入驻 </div>
                                            <div>supplier</div>
                                             <div></div>
                                        </div>
                                        <div class="Overviewbutton">点击查看>></div>
                               </div>
                                <div class="OverviewItem OverviewItem3" @click="goLisk('/business','distribution')">
                                    <div class="OverviewText">
                                            <div>分销商入驻 </div>
                                            <div>distributor</div>
                                             <div></div>
                                        </div>
                                        <div class="Overviewbutton">点击查看>></div>
                               </div>
                                <div class="OverviewItem OverviewItem4" @click="goLisk('/business','student')">
                                    <div class="OverviewText">
                                            <div>商学院 </div>
                                            <div>Business College</div>
                                             <div></div>
                                        </div>
                                        <div class="Overviewbutton">点击查看>></div>
                               </div>
                                <div class="OverviewItem OverviewItem5" @click="goLisk('/business','partner')">
                                    <div class="OverviewText">
                                            <div>合伙人加盟 </div>
                                            <div>partner</div>
                                             <div></div>
                                        </div>
                                        <div class="Overviewbutton">点击查看>></div>
                               </div>
                          </div>
                    </div>
                    <!-- <div class="EnterpriseView">
                         <square>
                              <span>企业动态</span>
                              <span>Enterprise dynamics</span>
                          </square>
                          <div class="Enterprise">
                               <div class="EnterpriseItem1">
                                    <div class="ViovTop">

                                    </div>
                                    <div class="ViovtextButtom">
                                        <div>金牌讲师教你如何做跨境抖音</div>
                                        <div>2022年8月26日，在ONEBO举行了免费的公开课，我们的金牌讲师大方地分享了她的跨···</div>
                                        <div>点击查看更多企业视频动态 >></div>
                                    </div>
                               </div>
                               <div class="EnterpriseItem2">
                                    <div class="Viovconter">
                                        <div class="Viov"></div>
                                        <div class="Viovtext">透明底框 白色字的标题</div>
                                    </div>
                                    <div class="Viovconter">
                                        <div class="Viov"></div>
                                        <div class="Viovtext">透明底框 白色字的标题测试白色字的标题测试</div>
                                    </div>
                               </div>
                              
                          </div>
                    </div> -->
                    <!-- 新闻信息 -->
                     <!-- <div class="NewView">
                          <div class="NewInfor">
                               <div class="NewInforItem1">
                                    <div class="Viovconter">
                                        <div class="Viov"></div>
                                        <div class="Viovtext">透明底框 白色字的标题 左对齐 拷贝</div>
                                    </div>
                                      <div class="Viovconter">
                                        <div class="Viov"></div>
                                        <div class="Viovtext">透明底框 白色字的标题 左对齐 拷贝</div>
                                    </div>
                                      <div class="Viovconter">
                                        <div class="Viov"></div>
                                        <div class="Viovtext">透明底框 白色字的标题 左对齐 拷贝</div>
                                    </div>
                                      <div class="Viovconter">
                                        <div class="Viov"></div>
                                        <div class="Viovtext">透明底框 白色字的标题 左对齐 拷贝</div>
                                    </div>
                               </div>
                               <div class="NewInforItem2">
                                    <div class="Viovconter">
                                        <div class="Viov"></div>
                                        <div class="Viovtext">透明底框 白色字的标题 左对齐 拷贝</div>
                                    </div>
                                  
                               </div>
                              
                          </div>
                    </div>
                    <div class="NewButton"><< 点击查看更多新闻 >></div> -->

                    
                    <div class="contactView2">
                        <div>联系ONEBO</div>
                        <div>Contact onebo</div>
                        <img src="../../assets/hour.png"/>
                        <div class="contactText">
                            <div>地址：广州市白云区鹤龙街道尖彭371号有趣创意社区A105号</div>
                            <div>邮政编码：510080</div>
                            <div>企业热线：15322087006</div>
                        </div>
                    </div>
                     
      
                    <div class="contactView">
                        <div class="contactItem">
                            <img  src="@/assets/GZH.jpg"/>
                            <span>微信公众号</span>
                            <span>Wechat official account</span>
                        </div>
                        <div class="contactItem">
                            <img src="@/assets/XCX.jpg"/>
                            <span>小程序</span>
                            <span>Applets</span>
                        </div>
                        <div class="contactItem">
                            <img src="@/assets/DY.jpg"/>
                            <span>抖音官方账号</span>
                            <span>Official tiktok account</span>
                        </div>
                        <div class="contactItem">
                            <img src="@/assets/DYS.png"/>
                            <span>微信视频号</span>
                            <span>Video account</span>
                        </div>
                    </div>
             </div>
        </div>
        <tool-view></tool-view>
        <content-foot2></content-foot2>
        <content-foot></content-foot>
    </div>
</template>
<script>
import circular from "@/components/circular.vue"
import square from '@/components/square.vue'
import ContentFoot2 from '@/components/contentFoot2.vue'
import ContentFoot from '@/components/contentFoot.vue'
import ToolView from '@/components/tool.vue'
import Swiper from "swiper"
export default {
    name:'index',
    components:{
        circular,
        square,
        ContentFoot2,
        ContentFoot,
        ToolView
    },
    created(){
        this.setHeight()
    },
    data(){
        return {
            ViewHeight:"",
        }
    },
    mounted(){
        new Swiper('.swiper-container', {
            loop: false,
            autoplay: 3000,
            slidesPerView: 1,
            pagination: '.swiper-pagination',
            paginationClickable: true
        })

        // this.$nextTick(()=>{
        //      var View = document.getElementsByClassName("swiper-slide");
        //      for(let i=0;i<View.length;i++){
        //         console.log(i)
        //          View[i].style.width=window.outerWidth+'px'
        //      }
        // })
       
    },
    methods:{
       
        setHeight(){
          //  this.ViewHeight=window.outerHeight-50+'px'

      //  判断显示屏幕的大小
           if(window.outerWidth>750){
               this.ViewHeight= window.outerHeight-20 +'px'
            }else{
                this.ViewHeight='500px'
            }
        },
        goLisk(path,name){
            this.$router.push({
                path:path,
                query:{
                    name:name
                }
            })
        },
       gobutton(){
           this.$router.push({
                path:'/plate',
                query:{
                    name:'BusinessPlate'
                }
            })
       },
       getbvious(index,path,name){
        if(window.outerWidth<760){
            let Events =document.getElementsByClassName("square");
            let TEverts=document.getElementsByClassName("showtext");
            let squarebutton =document.getElementsByClassName("squarebutton");
            
            if(TEverts[index].style.display =="block"){
                Events[index].style.height="200px";
                Events[index].style.transform="scale(1)";
                TEverts[index].style.display ="none";
                squarebutton[index].style.opacity=0.5;
                if(index<3){
                    Events[index+1].style.marginTop ="-110px";
                }
            }else{
                Events[index].style.height="240px";
                Events[index].style.transform="scale(1.03)";
                TEverts[index].style.display ="block";
                 squarebutton[index].style.opacity=1;
                if(index<3){
                   Events[index+1].style.marginTop ="5px";
                }
            }
        }else{
            this.$router.push({
                path:path,
                query:{
                    name:name
                }
            })
        }
       }
    }
}
</script>
<style scoped>

/*轮播图*/
.container{
        height: 100%;
        overflow: hidden;
        position: relative;
    }
.banner{
        height: 100%;
        width: 150%;
        overflow: hidden;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);

}
.bannerView{
    /* width:calc(150% - 50%); */
    width: 100%;
    height: 100%;
    position: relative;
}
.bannerImg{
    position:absolute;
    bottom: -30px;
    left: 0;
    z-index: 10;
    width: 100%;
}
.bannerImg img{
   width: 100%;
}
.carouselView {
    width:100%;
    height: 100%;
    display: flex;
    font-family: Source Han Sans CN;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.carouselView1{
    background-image: url('../../assets/two.jpg');
    color: #ffffff;
   
}
.carouselView2{
    color: #ffffff;
    background-image: url('../../assets/t-1.jpg');
   
}
.carouselView3{
    color: #ffffff;
    background-image: url('../../assets/t-2.png');
    
}
.carouselView4{
    color: #ffffff;
    background-image: url('../../assets/square4.png');
    
}
.ViewText{
   font-size: 40px;
   padding:10px 0 10px 0;
}
.ViewText2{
  font-size: 20px;
   padding:8px 0 8px 0;
}
.ViewButtons{
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ViewButton{
  padding: 8px 20px 8px 20px;
  border-radius: 30px;
  background: linear-gradient(93deg, #F98B3C, #FB6823);
}
.ViewButtons i{
   padding: 0 25px 0 25px;
   font-weight: bold;
}

    .indexView{
        margin: 0 auto;
        /* width: 100%; */
        max-width: 1920px;
        overflow: hidden;
        position: relative;
    }
    .indexCircular{
        position: absolute;
        left: -100px;
        top: 130px;
    }
    .indexContext{
        margin: 0 auto;
        width: 1000px;
    }
    .indexLogo{
         margin-top: 130px;
         text-align: left;
    }
    .indexConTitle{
        margin-top: 30px;
        text-align: left;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
    }
    .link{
        margin-top: 20px;
        width: 50px;
        height: 5px;
        background: #F97737;
        border-radius: 2px;
    }
    .Mapview{
        margin-top: 50px;
        background: #fff;
        padding: 50px 0 40px 0;
        box-shadow: 0px 7px 16px 0px rgba(0,0,0,0.19);

    }
    .Mapview img{
        width: 800px;
        height: 530px;
    }
    .Mapview div:nth-child(2){
        margin-left: 20px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
    }
    .indexText{
        margin-top: 200px;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
    }
    .oneboImg{
        margin-top: 60px;
    }
    .oneboImg img{
        width: 800px;
    }
    .link2{
        margin-top: 50px;
        width: 100%;
        height: 1px;
        background: #999999;
        border-radius: 1px;
    }
    .CompanyIntroduction{
         margin-top: 60px;
         display: flex;
         align-items: center;
         flex-direction: column;
    }
    .CompanySquare{
        margin-top: -175px;
        display: flex;
        width: 850px;
        justify-content:space-around;
        flex-wrap: wrap;
    }
    .CompanySquare .square:nth-child(3) {
        margin-top: 25px;
     }
    .CompanySquare .square:nth-child(4) {
        margin-top: 25px;
    }
    .square{
        width: 400px;
        height: 200px;
        box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 40%);
        cursor: pointer;
        transition: margin-top 1s ease;
    }
    .bg{
        width: 100%;
        height: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-size:100% 100% ;
    }
    /* .bg {
        width: 100%;
        height: 100%;
        position: relative; 
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-around;
    } */
    .bg1{
        background-image:url('../../assets/square1.png');
    }
     .bg2{
        background-image:url('../../assets/square2.png');
    }
     .bg3{
        background-image:url('../../assets/square3.png');
    }
     .bg4{
        background-image:url('../../assets/square4.png');
    }
    .squareText{
        position: absolute;
        top: 20px;
        left: 20px;
        text-align: left;
    }
    /* .squareText {
        position: absolute;
        top: 20px;
        left: 20px;
        text-align: left;
        margin-top: 20px;
    } */
    .squareText div:nth-child(1){
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #F6F3EC;
    }
    .squareText div:nth-child(2){
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #F6F3EC;
    }
    .square:hover{
        transform: scale(1.02);
    }
    .square:hover  .squareText div:nth-child(2){
        margin-top: 10px;
    }
    .squarebutton{
        position: absolute;
        right: 20px;
        bottom: 20px;
        width: 106px;
        height: 20px;
        line-height: 20px;
        border: 2px solid #FFF;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #F6F3EC;
        cursor: pointer;
    }
    /* .squarebutton[data-v-4e018bdc] {
       
        width: 106px;
        height: 20px;
        line-height: 20px;
        border: 2px solid #FFF;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #F6F3EC;
        cursor: pointer;
        align-self: end;
        margin-bottom: 20px;
    } */

   .OverviewView{
         margin-top: 200px;
         display: flex;
         align-items: center;
         flex-direction: column;
    }
    .Overview{
        margin-top: -175px;
        display: flex;
        width: 850px;
        justify-content:space-around;
        flex-wrap: wrap;
    }
    .OverviewItem{
        box-shadow: 0px 7px 16px 0px rgba(0,0,0,0.19);
        border-radius: 9px;
        position: relative;
        background-repeat: no-repeat;
        background-size:100% 100% ;
        cursor: pointer;
    }
    .OverviewItem1{
        width: 400px;
        height: 200px;
        background-image:url('../../assets/Overview1.png');
    }
    .OverviewItem2{
        width: 400px;
        height: 200px;
        background-image:url('../../assets/Overview2.png');
    }
      .OverviewItem3{
        width: 260px;
        height: 200px;
        margin-top: 25px;
        background-image:url('../../assets/Overview3.png');
    }
      .OverviewItem4{
        width: 260px;
        height: 200px;
        margin-top: 25px;
        background-image:url('../../assets/Overview4.png');
    }
      .OverviewItem5{
        width: 260px;
        height: 200px;
        margin-top: 25px;
        background-image:url('../../assets/Overview5.png');
    }
    .OverviewText{
        position: absolute;
        top: 20px;
        left: 20px;
        text-align: left;
    }
    
    .OverviewText div:nth-child(1){
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #F6F3EC;
    }
    .OverviewText div:nth-child(2){
        margin-top:20px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #F6F3EC;
    }
     .OverviewText div:nth-child(3){
        margin-top:20px;
        width: 51px;
        height: 6px;
        background: #FFFFFF;
    }
    .Overviewbutton{
        position: absolute;
        right: 20px;
        bottom: 20px;
        width: 106px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #F6F3EC;
        cursor: pointer;
    }

   .OverviewItem:hover .OverviewText{
         left: 25px;
    }
    .OverviewItem:hover .Overviewbutton{
         right: 15px;
    }
    .showtext{
        position: absolute;
        top: 120px;
        /* margin-top: 50px; */
        height: 40px;
        font-size: 16px;
        width: 90%;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFF;
        text-align: left;
        padding: 0px 20px 86px;
        background: linear-gradient(to bottom, #00000008, #000);
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        display: none;
    }
    .EnterpriseView{
         margin-top: 200px;
         display: flex;
         align-items: center;
         flex-direction: column;
    }
    .Enterprise{
        margin-top: -175px;
        display: flex;
        width: 850px;
        justify-content:space-around;
    }
    .EnterpriseItem1{
       width: 500px;
       height: 425px;
       background: #333333;
       display: flex;
       flex-direction: column;
    }
    .ViovTop{
        flex: 1;
        cursor: pointer;
    }
    .ViovtextButtom{
        height: 90px;
        padding: 10px 0 8px 0;
        background: #000000;
        text-align: left;
        text-indent: 1em;
        color: #F6F3EC;
        cursor: pointer;
    }
     .ViovtextButtom div:nth-child(1){
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        word-wrap:break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .ViovtextButtom div:nth-child(2){
        padding: 5px 0 8px 0;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        word-wrap:break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
     .ViovtextButtom div:nth-child(3){
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #8D8D8D;
        word-wrap:break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .EnterpriseItem2{
        width: 300px;
        height: 425px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .EnterpriseItem2>div{
        width: 300px;
        height: 200px;
        background: #333333;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #F6F3EC;
    }

    .NewView{
        margin-top: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;
     }
     .NewInfor{
        display: flex;
        width: 850px;
        justify-content: space-around;
     }
    
   .NewInforItem1{
      width:400px;
      height: 425px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      align-content: space-between;
   }
   .NewInforItem1 >div{
        width:185px;
        height:200px;
        background: #333333;
    }
    .NewInforItem2{
        width:400px ;
        background: #333333;
        height: 425px;
    }
     .NewInforItem2 >div{
        width:100% ;
        height: 100%;
        background: #333333;
    }
    .Viovconter{
        position: relative;
    }
    .Viov{
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
    .Viovtext{
         cursor: pointer;
        position:absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        line-height: 30px;
        background: #000000;
        opacity: 0.5;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        word-wrap:break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-indent: 1em;
    }

    .NewButton{
        width: 306px;
        height: 24px;
        font-size: 16px;
        margin: 30px auto 10px auto;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
    }
    .contactView2{
        position: relative;
        height: 300px;
        margin-top: 130px;
    }
    .contactView2>div:nth-child(1){
        height: 33px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
    }
    .contactView2>div:nth-child(2){
        margin-top: 10px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #999999;
    }
    .contactView2 img{
        width: 200px;
        height: 170px;
        position: absolute;
        top: 50px;
        left: 50px;
    }
    .contactText{
        position: absolute;
        top: 95px;
        left: 420px;
        width: 480px;
        height: 170px;
        text-align: left;
    }
    .contactText div:nth-child(2){
        padding: 20px 0;
    }
    .contactView{
        display: flex;
        justify-content: space-around;
        align-items: center;

    }
    .contactItem{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
       .contactItem img:nth-child(1){
         width: 130px;
         height: 130px;
         background: #FFF;
    }
    .contactItem span:nth-child(2){
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        padding: 10px 0;
    }
     .contactItem span:nth-child(3){
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #333333;
    }

</style>
<style lang="scss" scoped>
/*手机端*/
@media  screen and (max-width:760px) {
.banner{
    width: 200%;
}
.bannerImg {
    position: absolute;
    bottom: -7px;
    left: 0;
    z-index: 10;
    width: 100%;
}
.carouselView {
    background-position: 50% 20%;
    // background-size: auto !important;
   background-size: 250% 100%;

}
 .ViewText{
    font-size: 30px;
    font-weight: bold;
    padding: 40px 0 10px 0;
    margin-top: 100px;
    margin-bottom: 30px;
}
.ViewText2 {
    font-size: 14px;
    width: 300px;
    padding: 0px 30px 8px 30px;
}
.ViewButton{
    font-size: 12px;
}
.ViewButtons{
    margin-top: 100px;
}
.indexContext {
    margin: 0 auto;
    width: 100%;
}
.indexCircular{
    display: none;
}
.indexLogo{
    margin-top: 39px;
    text-align: center;
}
.indexConTitle {
    margin-top: 30px;
    text-align: left;
    font-size: 16px;
    color: #333333;
    padding: 0 20px;
}
.link {
    width: 110px;
    margin: 30px auto 10px auto;
}
.Mapview {
    margin: 30px auto 0;
    width: 95%;
    padding: 15px 0 20px 0;
}
.Mapview img {
    width: 100%;
    height: 210px;
}
.Mapview div:nth-child(2) {
    margin-left: 0;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

.indexText,.oneboImg {
    display: none;
}
.CompanySquare{
     width: 95%;
}
.squarebutton {
    position: absolute;
    right: 20px;
    bottom: 0;
    top: 35px;
    font-size: 12px;
    opacity: 0.5;
    border: 1px solid #FFF;
}
// .squarebutton[data-v-4e018bdc] {
  
//     font-size: 12px;
//     opacity: 0.5;
//     border: 1px solid #FFF;
//     align-self: flex-start;
//     margin-top: 30px;
// }
.square {
    border-radius: 9px;
}
.bg{
    border-radius: 9px;
    // flex-wrap: wrap;
}
.CompanySquare .square:nth-child(2) {
    margin-top: -110px;
}
.CompanySquare .square:nth-child(3) {
    margin-top: -110px;
}
.CompanySquare .square:nth-child(4) {
    margin-top: -110px;
}
.OverviewView {
    margin-top: 60px;
}
.Overview {
     width: 95%; 
}
.OverviewItem1,.OverviewItem2,.OverviewItem3,.OverviewItem4,.OverviewItem5 {
    width: 100% !important;
    height: 160px !important;
    margin-top: 6px;
}
.contactView2 {
    height: 500px;
}
.contactText {
    left: 50%;
    width: 300px;
    height: 170px;
    transform: translateX(-50%);
}
.contactView2 img{
    top: 270px;
    left: 50%;
    transform: translateX(-50%);
}
.contactView{
    width: 350px;
    flex-wrap: wrap;
    margin: 0 auto 40px auto;
}
}
</style>
