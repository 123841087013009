
export default {
    validEmpty:(data)=>{
        if(data == null || data == undefined ||  data ==""){
            return  true
        }else{
            return false
        }
    },
    throttling:(action,wait)=>{  //实现节流的方式 ，传参数：action 要执行的函数，time 对应的时间内
        let timer;
        let context,args;
        let run =() => {
           timer = setTimeout(()=>{
              action.apply(context,args);
              clearTimeout(timer);
              timer=null;
           },wait);
        }
        return function(){
          context =this;
          args=arguments;
          if(!timer){
              console.log("成功");
              run();
          }else(
              console.log("错误")
          )
        }
      }
}
