<template>
    <div class="contentfoot2">
        <div class="bgfoot"></div>
        <div Class="contentfootView">
           <div><img src="../assets/logo1.png" width="193" height="60"/></div>
           <div>
             <div class="contentfootItem">
                <div class="width contentfootColor1" @click="goFLisk('/group','CorporateCulture')">公司简介</div>
                <div class="width contentfootColor1">业务板块</div>
                <div class="width contentfootColor1" @click="goFLisk('/business','brand')">商业合作</div>
                <div class="width contentfootColor1" @click="goFLisk('/joinus','contactus')">加入我们</div>
             </div>
             <div class="contentfootLink"></div>
             <div class="contentfootItem">
                <div>
                    <div class="width contentfootColor2" @click="goFLisk('/group','CorporateCulture')">企业文化</div>
                    <!-- <div class="width contentfootColor2" @click="goFLisk('/group','LatestNews')">最新动态</div> -->
                </div>
                <div>
                    <div class="width contentfootColor2"  @click="goFLisk('/plate','BusinessPlate')">业务板块</div>
                </div>
                <div>
                    <div class="width contentfootColor2" @click="goFLisk('/business','brand')">品牌商入驻</div>
                    <div class="width contentfootColor2" @click="goFLisk('/business','supplier')">供应商入驻</div>
                    <div class="width contentfootColor2" @click="goFLisk('/business','distribution')">分销商入驻</div>
                    <div class="width contentfootColor2" @click="goFLisk('/business','student')">商学院</div>
                    <div class="width contentfootColor2" @click="goFLisk('/business','partner')">合伙人加盟</div>
                    <div class="width contentfootColor2" @click="goFLisk('/business','query')">进度查询</div>
                </div>
                <div>
                    <div class="width contentfootColor2" @click="goFLisk('/joinus','contactus')">联系我们</div>
                    <div class="width contentfootColor2" @click="goFLisk('/joinus','recruitment')">人才招聘</div>
                </div>
             </div>
             
           </div>
        </div>
        <div class="addime"> &copy;{{ year }}&nbsp;&nbsp;广东一播科技有限公司版权所有 <a style="color:#FFF" target="_blank" href="https://beian.miit.gov.cn">粤ICP备20042940号-2</a> | 公司地址：广州市白云区鹤龙街道尖彭371号有趣创意社区A105号</div>
    </div>
</template>
<script>
export default {
    name:'contentfoot2',
    data(){
        return {
       
        }
    },
    methods:{
         goFLisk(path,name){
            this.$router.push({
                path:path,
                query:{
                    name:name
                }
            })
        }
    }
}
</script>
<style scoped>
   .contentfoot2{
      margin-top: 100px;
      height: 500px;
      overflow: hidden;
      position: relative;
   }
   .bgfoot{
        height: 800px;
        width: 100%;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        background: linear-gradient(267deg, #F6944B, #FA6A2E);
        transform: scaleX(2);
   }
   .contentfootView{
        width: 1000px;
        position: absolute;
        top: 80px;
        left: 42%;
        z-index: 100;
        transform: translateX(-40%);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
   }
   .contentfootItem{
        display: flex;
        flex-direction: row;
   }
   .contentfootColor1{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
   }
    .contentfootColor2{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #FFFFFF;
        opacity: 0.5;
        cursor: pointer;
   }
   .contentfootColor2:hover{
    font-weight: bold;
    opacity: 1;
   }
   .width{
    width: 160px;
    height: 40px;
    text-align: center;
    line-height: 40px;
   }
   .contentfootLink{
    width: 100%;
    height: 2px;
    background: #FFFFFF;
    opacity: 0.3;
   }
   .addime{
    width: 100%;
     position: absolute;
     bottom: 0;
     left: 0;
     height: 40px;
     line-height: 40px;
     /* background: #000000;
     opacity: 0.2; */
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: #FFFFFF;
   }
</style>
<style lang="scss" scoped>
/*手机端*/
@media  screen and (max-width:760px) {
    .contentfoot2{
       display: none;
    }
}

/*PC端*/
@media  screen and(min-width:1200px) {
     .contentfoot2{
       display:block;
    }
}
</style>