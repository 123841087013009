import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'
import NewLayout from '../views/NewLayout.vue'
import Index from "../views/linkview/Index.vue"

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    redirect:'NewLayout',
  },
  //新版
  {
    path: '/NewLayout',
    name: 'NewLayout',
    component: NewLayout,
    redirect:'indexAdmin',
    children:[
      {
        path: '/indexAdmin',
        name: 'indexAdmin',
        component: () => import(/* webpackChunkName: "indexAdmin" */ '../views/homePageNew/index/indexAdmin')
      },
      {
        path: '/ERPAdmin',
        name: 'ERPAdmin',
        component: () => import(/* webpackChunkName: "ERPAdmin" */ '../views/homePageNew/index/ERPAdmin')
      },
      {
        path: '/ERPSIM',
        name: 'ERPSIM',
        component: () => import(/* webpackChunkName: "indexAdmin" */ '../views/homePageNew/index/ERPSIM')
    },
        {
            path: '/productAdmin',
            name: 'productAdmin',
            component: () => import(/* webpackChunkName: "indexAdmin" */ '../views/homePageNew/index/productAdmin')
        },
        {
            path: '/supplierAdmin',
            name: 'supplierAdmin',
            component: () => import(/* webpackChunkName: "indexAdmin" */ '../views/homePageNew/index/supplierAdmin')
        },
       
        {
            path: '/ERPShopAdmin',
            name: 'ERPShopAdmin',
            component: () => import(/* webpackChunkName: "indexAdmin" */ '../views/homePageNew/index/ERPShopAdmin')
        },
        {
            path: '/serverAdmin1',
            name: 'serverAdmin1',
            component: () => import(/* webpackChunkName: "serverAdmin1" */ '../views/homePageNew/index/serverAdmin1')
        },
        {
            path: '/serverAdmin2',
            name: 'serverAdmin2',
            component: () => import(/* webpackChunkName: "serverAdmin2" */ '../views/homePageNew/index/serverAdmin2')
        },
        {
          path: '/serverAdmin3',
          name: 'serverAdmin3',
          component: () => import(/* webpackChunkName: "serverAdmin3" */ '../views/homePageNew/index/serverAdmin3')
      },
        {
            path: '/commertAdmin/:name',
            name: 'commertAdmin',
            component: () => import(/* webpackChunkName: "commertAdmin" */ '../views/homePageNew/index/commertAdmin')
        },
        {
          path: '/JoinusAdmin',
          name: 'JoinusAdmin',
          component: () => import(/* webpackChunkName: "JoinusAdmin" */ '../views/homePageNew/index/JoinusAdmin')
        },
        {
          path: '/contactAdmin',
          name: 'contactAdmin',
          component: () => import(/* webpackChunkName: "contactAdmin" */ '../views/homePageNew/index/contactAdmin')
        },
        {
          path: '/newsAdmin',
          name: 'newsAdmin',
          component: () => import(/* webpackChunkName: "newsAdmin" */ '../views/homePageNew/index/newsAdmin')
        },
        {
          path: '/setWordAdmin',
          name: 'setWordAdmin',
          component: () => import(/* webpackChunkName: "setWordAdmin" */ '../views/homePageNew/index/setWordAdmin')
        },
        {
          path: '/commercialAdmin',
          name: 'commercialAdmin',
          component: () => import(/* webpackChunkName: "commercialAdmin" */ '../views/homePageNew/index/commercialAdmin')
        },
        {
          path: '/mediaAdmin',
          name: 'mediaAdmin',
          component: () => import(/* webpackChunkName: "mediaAdmin" */ '../views/homePageNew/index/mediaAdmin')
        },
        {
          path: '/generalAdmin',
          name: 'generalAdmin',
          component: () => import(/* webpackChunkName: "generalAdmin" */ '../views/homePageNew/index/generalAdmin')
        },
        {
          path: '/corporationAdmin',
          name: 'corporationAdmin',
          component: () => import(/* webpackChunkName: "corporationAdmin" */ '../views/homePageNew/index/corporationAdmin')
        },
        {
          path: '/civilizationAdmin',
          name: 'civilizationAdmin',
          component: () => import(/* webpackChunkName: "civilizationAdmin" */ '../views/homePageNew/index/civilizationAdmin')
        },
        {
          path: '/SupplierAdminform',
          name: 'SupplierAdminform',
          component: () => import(/* webpackChunkName: "SupplierAdminform" */ '../views/homePageNew/index/SupplierAdminform')
        },
        {
          path: '/productsDetails',
          name: 'productsDetails',
          component: () => import(/* webpackChunkName: "SupplierAdminform" */ '../views/homePageNew/index/productsDetails')
        },
        {
          path: '/mifiDetails',
          name: 'mifiDetails',
          component: () => import(/* webpackChunkName: "SupplierAdminform" */ '../views/homePageNew/index/mifiDetails')
        },
        {
          path: '/ufiDetails',
          name: 'ufiDetails',
          component: () => import(/* webpackChunkName: "SupplierAdminform" */ '../views/homePageNew/index/ufiDetails')
        },
    ],
  },
  

  
  //旧版
  {
    path: '/layout',
    name: 'layout',
    component: Layout,
    redirect:'index',
    children:[
      {
        path:'/index',
        name:'index',
        component:Index,
      },
      {
        path:'/group',
        name:'group',
        component:()=>import(/* webpackChunkName: "GroupInfor" */ '../views/linkview/GroupInfor.vue')
      },
      {
        path:'/plate',
        name:'plate',
        component:()=>import(/* webpackChunkName: "Business" */ '../views/linkview/Plate.vue')
      },
      {
        path:'/business',
        name:'business',
        component:()=>import(/* webpackChunkName: "Business" */ '../views/linkview/Business.vue')
      },
      {
        path:'/joinus',
        name:'joinus',
        component:()=>import(/* webpackChunkName: "Joinus" */ '../views/linkview/Joinus.vue')
      },
      {
        path:'/verdron',
        name:'verdron',
        component:()=>import(/* webpackChunkName: "SupplierSettlement" */ '../views/linkview/SupplierSettlement.vue')
      }
    ]
  },
  {
    path:'/joinus/recruitmentDetail',
    name:'recruitmentDetail',
    component:()=>import(/* webpackChunkName: "RecruitmentDetail" */ '../views/linkview/RecruitmentDetail.vue')
  },
  //公众号
  {
    path:'/brandinfor',
    name:'brandinfor',
    component:()=>import(/* webpackChunkName: "brandinfor" */ '../views/component/publicNumber/brandinfor.vue') //品牌
  },
  {
    path:'/supplierinfor',
    name:'supplierinfor',
    component:()=>import(/* webpackChunkName: "supplierinfor" */ '../views/component/publicNumber/supplierinfor.vue') //供应商
  },
  {
    path:'/partnerinfor',
    name:'partnerinfor',
    component:()=>import(/* webpackChunkName: "partnerinfor" */ '../views/component/publicNumber/partnerinfor.vue') //合伙人
  },

]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
  // base: process.env.BASE_URL,
  routes
})

router.afterEach((to,from) =>{
    // let Event = document.documentElement ||  document.body
    // Event.scrollTop =0
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  })
})

export default router
