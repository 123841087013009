<template>
    <div class="toolView">
        <div class="toolTop">
            <div>在线<br/>咨询</div>
            <div></div>
            <div></div>
        </div>
        <div class="toolfoot" @click.stop="toTop"><div></div></div>
    </div>
</template>
<script>
export default {
    name:'tool',
    methods:{
       toTop(){
          window.scrollTo({
                top: 0,
                behavior: 'smooth',
           })
       }
    }
}
</script>
<style lang="scss" scoped>
.toolView{
    position: fixed;
    top:200px;
    right: 60px;
    z-index: 120;
    .toolTop{
        background: linear-gradient(to bottom, #F6944B, #FA6A2E);
        padding: 15px 10px;
        border-radius: 15px;
        & div{
            width: 60px;
            height: 60px;
            cursor: pointer;
        }
        & div:nth-child(1){
            font-size:18px;
            font-family: Source Han Sans CN;
            font-weight: 700;
            color: #FFF;
        }
        & div:nth-child(2){
            background-image: url('../assets/tool.png');
            background-position: -1px 1px;
            border-bottom: 1px solid #7c777773;
            border-top:1px solid#7c777773
        }
        & div:nth-child(3){
            background-image: url('../assets/tool.png');
            background-position: -1px -76px;
        }
    }
    .toolfoot{
        margin-top: 20px;
         padding: 15px 10px;
         border-radius: 15px;
         background: linear-gradient(to bottom, #F6944B, #FA6A2E);
         & div{
            width: 60px;
            height: 40px;
            cursor: pointer;
            background-image: url('../assets/tool.png');
            background-position: -1px -190px;
         }
    }
}
/*手机端*/
@media  screen and (max-width:760px) {
    .toolView{
        display: none;
    }
}
</style>